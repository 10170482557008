export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "Midi Music Generator",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://skytnt-midi-composer.hf.space",
    domain: "midimusicgenerator.com",
    gaId: "G-B2NGV7XD06",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
